.compte {
    display: flex;
    width: 100%;
    height: 100vh;
}

.compte .part_left {
    background-color: #1539cf;
    width: 20%;
    height: 100vh;
}

.compte .part_right {
    background-color: #C7C6C4;
    width: 80%;
    height: 100vh;

}

.part_left .logo img {
    width: 100%;
    height: 38vh;
}

.part_left .links {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: #FFFFFF;
    padding-top: 16px;
}

.link_dashboard,
.link_offres,
.link_company,
.link_utilisateurs,
.link_compte {
    text-decoration: none;
    color: black;
    padding-top: 24px;
    margin-top: 10px;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 20px;
    border-right: 4px solid #1539cf;
    width: 90%;
    text-align: left;
    display: flex;
    align-items: center;
}

.part_right .content_right {
    width: 85%;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;

}

.profil {
    display: flex;
    justify-content: space-between;
    margin-bottom: 80px;
}

.profil .profil_right {
    background-color: #FFFFFF;
    height: 40vh;
    width: 40%;
    margin-left: 10px;
    border-radius: 13px;
    margin-bottom: 18px;
    margin-top: 25px;
}

.profil .profil_left {
    background-color: #FFFFFF;
    height: 46vh;
    width: 60%;
    margin-right: 10px;
    border-radius: 13px;
    margin-bottom: 18px;
    margin-top: 25px;
}

.form_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.form_content input {
    height: 5vh;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 13px;
    background-color: #C7C6C4;
    border: none;
}

.form_middle {
    display: flex;
    justify-content: space-between;
}

.form_middle input {
    width: 30%;
    height: 5vh;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 13px;
    background-color: #C7C6C4;
    border: none;
}

.from_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.profil_right h2 {
    text-align: center;
}

.from_content input {
    width: 80%;
    height: 5vh;
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    border: none;
    background-color: #C7C6C4;
    padding-left: 10px;
}

.btn_change {
    margin-top: 20px;
    border: none;
    border-radius: 14px;
    background-color: #1539cf;
   
    color: #FFFFFF;
}


.input_password {
    width: 90%;
    height: 5vh;
    margin-top: 15px;
    border-radius: 13px;
    background-color: #C7C6C4;
    border: none;
    padding: 0px 15px 0 0;
}

.input_name {
    width: 90%;
    height: 5vh;
    margin-top: 15px;
    border-radius: 13px;
    background-color: #C7C6C4;
    border: none;
    padding: 0px 15px 0 15px;
}

.input_email {
    width: 90%;
    height: 5vh;
    margin-top: 15px;
    border-radius: 13px;
    background-color: #C7C6C4;
    border: none;
    padding: 0px 15px 0 15px;
}