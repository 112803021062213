.offres {
    display: flex;
    width: 100%;
    height: max-content;
}

.offres .part_left {
    background-color: #1539cf;
    width: 20%;
    height: 100vh;
}

.offres .part_right {
    background-color: #C7C6C4;
    width: 80%;
    height: 130vh;
}

.part_left .logo img {
    width: 100%;
    height: 38vh;
}

.part_left .links {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: #FFFFFF;
    padding-top: 16px;
}

.link_dashboard,
.link_offres,
.link_company,
.link_utilisateurs,
.link_compte {
    text-decoration: none;
    color: black;
    padding-top: 24px;
    margin-top: 10px;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 20px;
    border-right: 4px solid #1539cf;
    width: 90%;
    text-align: left;
    display: flex;
    align-items: center;
}

.part_right .container_right {
    width: 85%;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    text-align: left;
}

.container_offres {
    width: 100%;
    height: 72vh;
    margin-top: 60px;
    margin-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 14px;
    margin-bottom: 10px;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
}

.container_offres .elements_offres {
    display: flex;
    width: 100%;
    flex-direction: column-reverse;
    padding-top: 15px;
    padding-bottom: 15px;
}

.btn_add {
    display: flex;
    justify-content: end;
    
}

.add_offer {
    width: 20%;
    border-radius: 20px;
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: space-around;
    margin-right: 10px;
}

.delete_offer{
    width: 20%;
    border-radius: 20px;
    align-items: center;
    display: flex;
    font-size: 18px;
    justify-content: space-around;
}


.elements_offres {
    height: max-content;
    /* overflow-y: scroll;
    scroll-snap-type: y mandatory; */
}

.elements_offres Posts {
    scroll-snap-align: start;
}