.dashboard {
    display: flex;
    width: 100%;
    height: 100vh;
}

.dashboard .part_left {
    background-color: #1539cf;
    width: 20%;
    height: 100vh;
}

.dashboard .part_right {
    background-color: #C7C6C4;
    width: 80%;
    height: 100vh;

}

.part_left .logo img {
    width: 100%;
    height: 38vh;
}

.part_left .links {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: #FFFFFF;
    padding-top: 16px;
}

.link_dashboard,
.link_offres,
.link_company,
.link_utilisateurs,
.link_compte {
    text-decoration: none;
    color: black;
    padding-top: 24px;
    margin-top: 10px;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 20px;
    border-right: 4px solid #1539cf;
    width: 90%;
    text-align: left;
    display: flex;
    align-items: center;
}

.part_right .container_right {
    width: 85%;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    text-align: left;
}

.container_right .content {
    display: flex;
    justify-content: space-between;
}

.content .block_offres {
    background-color: #E3F5FF;
    width: 23%;
    height: 33vh;
    border-radius: 13px;
    padding: 0 0 0 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content .block_company {
    background-color: #E5ECF6;
    width: 23%;
    height: 33vh;
    border-radius: 13px;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.size_number {
    font-size: 33px;
    font-weight: bolder;
}

.content .block_utilisateur {
    background-color: #E3F5FF;
    width: 23%;
    height: 33vh;
    border-radius: 13px;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.content .block_compte {
    background-color: #E5ECF6;
    width: 23%;
    height: 33vh;
    border-radius: 13px;
    padding: 0 0 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.part_right .main_dashboard {
    display: flex;
    justify-content: space-between;
    margin-top: 149px;
}

.main_dashboard .dashboard_left {
    background-color: #E3F5FF;
    margin-right: 10px;
    width: 50%;
    height: 50vh;
    border-radius: 13px;
}

.main_dashboard .dashboard_right {
    background-color: #E5ECF6;
    margin-left: 10px;
    width: 50%;
    height: 50vh;
    border-radius: 13px;
}