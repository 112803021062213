.register{
    width: 100%;
    height: 100vh;
    display: flex;
}

.register .part_left{
    width: 40%;
    height: 100vh;
    background: #1539cf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.register .part_right{
    width: 60%;
    height: 100vh;
    background: #C7C6C4;
}

.content_form{
    display: flex;
    justify-content: center;
}

.container_formulaire{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1%;
}

.container_formulaire input {
    margin-top: 18px;
    height: 40px;
    width: 50%;
    border: none;
    border-radius: 10px;
    padding-left: 15px;
}

span{
    color: #1539cf;
    text-align: right;
}

.textarea{
    width: 50%;
    height: 16vh;
    margin-top: 35px;
    border-radius: 13px;
    background-color: #FFF;
    border: none;
    padding-left: 15px;
    padding-top: 15px;
}

.container_formulaire  .content_btn{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

button {
    margin-top: 18px;
    height: 40px;
    width: 50%;
    background-color: #1539cf;
    border: none;
    color:aliceblue;
    font-size: 15px;
    border-radius: 10px;
}
