.utilisateurs{
    display: flex;
    width: 100%;
    height: 100vh;
}

.space_icon button {
    margin-left: 5px;
    margin-right: 5px;
}

.utilisateurs .part_left{
    background-color: #1539cf;
    width: 20%;
    height: 100vh;
}

.utilisateurs .part_right{
    background-color: #C7C6C4;
    width: 80%;
    height: 100vh;
}

.part_left .logo img {
    width: 100%;
    height: 38vh;
}

.part_left .links {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    background-color: #FFFFFF;
    padding-top: 16px;
}

.link_dashboard,
.link_offres,
.link_company,
.link_utilisateurs,
.link_compte {
    text-decoration: none;
    color: black;
    padding-top: 24px;
    margin-top: 10px;
    padding-left: 25px;
    margin-right: 10px;
    font-size: 20px;
    border-right: 4px solid #1539cf;
    width: 90%;
    text-align: left;
    display: flex;
    align-items: center;
}

.part_right .container_right{
    width: 85%;
    height: 90vh;
    margin-left:  auto;
    margin-right:  auto;
    margin-top: 40px;
    text-align: left;
}

.container_right .content_utilisateur{
    width: 100%;
    height: 51vh;
    margin-top: 60px;
    margin-bottom: 30px;
    background-color: #FFFFFF;
    border-radius: 14px;
    margin-bottom: 10px;
}