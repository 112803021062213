.forgetpass{
    width: 100%;
    height: 100vh;
    display: flex;
}

.forgetpass .part_left{
    width: 40%;
    height: 100vh;
    background: #1539cf;
    display: flex;
    justify-content: center;
    align-items: center;
}

.forgetpass .part_right{
    width: 60%;
    height: 100vh;
    background: #C7C6C4;
}

.content_form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.container_formulaire{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
}

.container_formulaire input {
    margin-top: 18px;
    height: 40px;
    width: 50%;
    border: none;
    border-radius: 10px;
}

span{
    color: #1539cf;
    text-align: right;
}

.container_formulaire button {
    margin-top: 18px;
    height: 40px;
    width: 50%;
    background-color: #1539cf;
    border: none;
    border-radius: 10px;
}