.content_modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    background: #07060655;
    display: flex;
    align-items: center;
    justify-content: center;

}

.btn_close {
    background: none;
    border: none;
    font-size: 30px;
    color: white;
    position: absolute;
    top: 40px;
    right: 45px;
    z-index: 12;
}

.modal {
    width: fit-content;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0px 187px 75px rgba(0, 0, 0, 0.01), 0px 105px 63px rgba(0, 0, 0, 0.05), 0px 47px 47px rgba(0, 0, 0, 0.09), 0px 12px 26px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
    border-radius: 26px;
    max-width: 650px;
}


.separator {
    width: calc(100% - 20px);
    gap: 10px;
    color: #8B8E98;
    margin: 0 10px;
}


.credit-card-info--form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.input_container {
    width: 100%;
    height: fit-content;
    display: flex;
    gap: 5px;
}


.col-items{
    display: flex;
    flex-direction: column;
}

.input_label {
    font-size: 10px;
    color: #8B8E98;
    font-weight: 600;
}

.input_field {
    width: auto;
    height: 40px;
    padding: 0 0 0 16px;
    border-radius: 9px;
    outline: none;
    background-color: #F2F2F2;
    border: 1px solid #e5e5e500;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.textaera_field{
    width: 33.5vw;
    height: 200px;
    padding: 0 0 0 16px;
    border-radius: 9px;
    outline: none;
    background-color: #F2F2F2;
    border: 1px solid #e5e5e500;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.input_field:focus {
    border: 1px solid transparent;
    box-shadow: 0px 0px 0px 2px #242424;
    background-color: transparent;
}

.btn_position{
    margin-bottom: 20px;
}

.purchase--btn {
    width: 90px;
    background: #F2F2F2;
    border-radius: 11px;
    border: 0;
    outline: none;
    color: #ffffff;
    font-size: 13px;
    font-weight: 700;
    background: linear-gradient(180deg, #363636 0%, #1B1B1B 50%, #000000 100%);
    box-shadow: 0px 0px 0px 0px #FFFFFF, 0px 0px 0px 0px #000000;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
}

.purchase--btn:hover {
    box-shadow: 0px 0px 0px 2px #FFFFFF, 0px 0px 0px 4px #0000003a;
}
