
.post_content{
    background-color: #b9bcc9;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    padding: 10px;
}

.items_space{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.items_space .circle_fill{
   font-size: 25px;
   margin-right:10px;
}


.post_container{
    display: flex;
    justify-content: space-between;
}


.post_container .column1{
    width: 30%;
}

.post_container .column2{
    width: 30%;
}

.post_container .column3{
    width: 40%;
}